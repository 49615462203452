import { registerEmailCode, register } from '@/api/auth/register'
import { login } from "@/api/auth/login"
import { getToken, setToken, removeToken } from "@/utils/auth"
import { captcha } from "@/api/website"
import { tree } from '@/api/shop'
import { addToCart } from "@/api/goods/cart"
import { addCarts } from "@/api/pc"

export default {
    name: "login",
    components: {

    },
    data: () => {
        return {
            login: true,
            loginTitle: "Sign in",
            spanAtive: "spanAtive",
            spanAtive1: "",
            textImg: false,
            email: "",
            emailVerify: "",
            inputType: "password",
            passwordImg: false,
            passwordSwitch: true,
            passwordText: "",
            inputType1: "password",
            passwordImg1: false,
            passwordSwitch1: true,
            passwordText1: "",
            radio: false,
            codeText: "Get verification code",
            codeShow: true,
            codeTime: 60,
            captcha: {
                id: 0,
                img: ''
            },
            dialogVisible: false,
            codeVerify: '',
            autoLoginRange: 0,
            pathUrl: '',
            // 需要翻译的静态文本
            translateTextData: {
                SIGN_IN: 'SIGN IN',
                SIGN_UP: 'SIGN UP',
                Email: 'Email',
                Enter_your_email: 'Enter your email',
                Password: 'Password',
                Enter_your_password: 'Enter your password',
                Forgot_password: 'Forgot password?',
                Confirm_Password: 'Confirm Password',
                Please_confirm_your_password: 'Please confirm your password',
                New_user_coupons: 'New user get 10%OFF Coupon',
                Loading_failed: 'Loading failed'
            }
        }
    },
    watch: {
        '$route': 'fetchData',
        buy: {
            deep: true,
            handler: function (val) {
                this.buy = val;
                this.fetchData();
            },
        },
        buyNow: {
            deep: true,
            handler: function (val) {
                this.buyNow = val;
                this.fetchData();
            },
        },
        email: {
            deep: true,
            handler: function (val) {
                this.email = val;
                this.fetchData();
            },
        },
        passwordText: {
            deep: true,
            handler: function (val) {
                this.passwordText = val;
                this.fetchData();
            },
        },
        passwordText1: {
            deep: true,
            handler: function (val) {
                this.passwordText1 = val;
                this.fetchData();
            },
        },
    },
    props: ["buy", "buyNow"],
    created() {
        this.getCaptcha();
        this.getTranslateText();
    },
    mounted() {
        // this.$forceUpdate();
    },
    computed: {
    },
    beforeRouteEnter(to, from, next) {
        next(vm=>{    
            vm.pathUrl = from.fullPath;
        })
    },
    methods: {
        // 翻译静态文本
        getTranslateText() {
            let strText = '';
            let i = 0;
            let translateData = Object.values(this.translateTextData); // 创建一个包含对象中每个属性的值的数组
            translateData.forEach((item,index) => {
                strText += item + '\n'
            })
            // 翻译
            this.$fanyi.translateData({
                query: strText,
                success: resData => {
                    for(let key in this.translateTextData){
                        this.translateTextData[key] = resData[i]
                        i++;
                    }
                }
            })
        },
        keyDownClick(e) {
            if(e.keyCode == 13){
                this.signClick();
            }
        },
        emailCodeClick() {
            const data = {
                accountEmail: this.email,
                isCaptcha: false
            }
            registerEmailCode(data).then(res => {
                if (res.code == 0) {
                    this.getCode();
                }
            }).catch(err => {
                this.$message.error(err.message);
                // console.log(err)
            })
        },
        getCode() {
            this.codeShow = false;
            this.codeTime = 60;
            let time = setInterval(() => {
                this.codeTime--;
                if (this.codeTime === 0) {
                    this.codeText = "Reacquire";
                    this.codeShow = true;
                    clearInterval(time)
                }
            }, 1000)
        },
        signClick() {
            if(this.email == ""){
                this.$message.info("Please enter your email address");
                return;
            }
            if (this.login) {
                this.getLogin();
                // this.dialogVisible = true;
            } else {
                if(this.passwordText == this.passwordText1){
                    const data = {
                        email: this.email,
                        password: this.passwordText,
                        suid: localStorage.getItem('soyegoPromotionSuid'),
                        // code: this.emailVerify,
                        isCaptcha: false
                    }
                    this.getRegister(data);
                }else{
                    this.$message.warning("Please confirm your password");
                }
            }
        },
        getRegister(data) {
            var that = this;
            register(data).then(res => {
                if (res.code == 0) {
                    login({ username: data.email, password: data.password }).then(res1 => {
                        if(res1.code == 0){
                            setToken(res1.data.token, this.autoLoginRange);
                            if(that.buy == true){
                                let cartList = localStorage.getItem("cartProductsSoyego");
                                addCarts({ data: cartList }).then( res => {
                                    if(res.code == 0 && res.data){
                                        localStorage.removeItem("cartProductsSoyego");
                                        that.$emit("close");
                                    }
                                }).catch( err => {
                                    // this.$message.error('Add failed');
                                })
                            }else if(that.buyNow == true){
                                that.$emit("close");
                            }else{
                                this.back();
                            }
                        }
                    }).catch(_err => {
                        this.$message.error(_err.message);
                    })
                }
            }).catch(err => {
                this.$message.error("Incorrect password or vertification code");
            })
        },
        dialogClick() {
            this.getLogin();
        },
        getLogin() {
            var that = this;
            // , captcha_id: this.captcha.id, captcha_code: this.codeVerify
            login({ username: this.email, password: this.passwordText }).then(res => {
                if(res.code == 0){
                    setToken(res.data.token, this.autoLoginRange);
                    this.dialogVisible = false;
                    if(that.buy == true){
                        let cartList = localStorage.getItem("cartProductsSoyego");
                        addCarts({ data: cartList }).then( res => {
                            if(res.code == 0 && res.data){
                                localStorage.removeItem("cartProductsSoyego");
                                that.$emit("close");
                            }
                        }).catch( err => {
                            // this.$message.error('Add failed');
                        })
                    }else if(that.buyNow == true){
                        that.$emit("close");
                    }else{
                        if(this.pathUrl == "/emailCode" || this.pathUrl == "/forgottenPassword"){
                            this.$router.push("/");
                        }else{
                            this.back();
                        }
                    }
                }
            }).catch(_err => {
                this.$message.error(_err.message);
                // reject(_err)
            })
        },
        back() {
            this.$router.go(-1);
            // this.$router.next()
        },
        /**
         * 获取验证码
         */
        getCaptcha() {
            this.captcha.img = "";
            captcha({
                captcha_id: this.captcha.id
            }).then(res => {
                if (res.code >= 0) {
                    this.captcha.id = res.data.id
                    this.captcha.img = res.data.img
                    this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
                }
            }).catch(err => {
                this.$message.error(err.message)
            })
        },
        fetchData() {
            this.$forceUpdate();
        },
        textFocus() {
            this.textImg = true
        },
        textBlur() {
            this.textImg = false
        },
        textClick() {
            this.email = ""
        },
        passwordFocus() {
            this.passwordImg = true
            this.passwordSwitch = true
        },
        // passwordBlur() {
        //     this.passwordImg = false
        // },
        passwordClick() {
            if (this.passwordImg == true) {
                this.passwordSwitch = !this.passwordSwitch
            }
            if (this.passwordSwitch) {
                this.inputType = "password"
            } else {
                this.inputType = "text"
            }
        },
        passwordFocus1() {
            this.passwordImg1 = true
            this.passwordSwitch1 = true
        },
        // passwordBlur() {
        //     this.passwordImg = false
        // },
        passwordClick1() {
            if (this.passwordImg1 == true) {
                this.passwordSwitch1 = !this.passwordSwitch1
            }
            if (this.passwordSwitch1) {
                this.inputType1 = "password"
            } else {
                this.inputType1 = "text"
            }
        },
        loginClick(val) {
            if (val) {
                this.spanAtive = "spanAtive";
                this.spanAtive1 = "";
                this.loginTitle = this.translateTextData.SIGN_IN;
            } else {
                this.spanAtive = "";
                this.spanAtive1 = "spanAtive";
                this.loginTitle = this.translateTextData.SIGN_UP;
            }
            this.textImg = false;
            this.email = "";
            this.emailVerify = "";
            this.inputType = "password";
            this.passwordImg = false;
            this.passwordSwitch = true;
            this.passwordText = "";
            this.login = val;
        }
    }
}
