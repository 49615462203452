<template>
    <div class="login">
        <div class="img">
            <img src="@/assets/images/login/img.png">
        </div>
        <div class="login-main">
            <div class="title">
                <span :class="spanAtive" @click="loginClick(true)">{{translateTextData.SIGN_IN}}</span>
                <span :class="spanAtive1" @click="loginClick(false)">{{translateTextData.SIGN_UP}}</span>
            </div>
            <div class="main-sign" v-show="login">
                <div>
                    <span>{{translateTextData.Email}}</span>
                    <input :style="email != '' ? 'color: #330000;border-bottom: 1px solid #330000;' : ''" type="text" v-model="email" :placeholder="translateTextData.Enter_your_email" @focus="textFocus" @blur="textBlur">
                    <img v-show="textImg" @mousedown="textClick()" src="@/assets/images/login/x.png">
                </div>
                <div>
                    <span>{{translateTextData.Password}}</span>
                    <input :style="passwordText !=  '' ? 'color: #330000;border-bottom: 1px solid #330000;' : ''" :type="inputType" v-model="passwordText" :placeholder="translateTextData.Enter_your_password" @keydown="keyDownClick" @focus="passwordFocus">
                    <img v-show="passwordImg && passwordSwitch" @mousedown="passwordClick" src="@/assets/images/login/closeEyes.png">
                    <img v-show="passwordImg && !passwordSwitch" @mousedown="passwordClick" src="@/assets/images/login/openEyes.png">
                </div>
                <p @click="$router.push('/forgottenPassword')">{{translateTextData.Forgot_password}}</p>
            </div>
            <div class="main-sign" v-show="!login">
                <div>
                    <span>{{translateTextData.Email}}</span>
                    <input :style="email != '' ? 'color: #330000;border-bottom: 1px solid #330000;' : ''" type="text" v-model="email" :placeholder="translateTextData.Enter_your_email" @focus="textFocus" @blur="textBlur">
                    <img v-show="textImg" @mousedown="textClick()" src="@/assets/images/login/x.png">
                </div>
                <div>
                    <span>{{translateTextData.Password}}</span>
                    <input :style="passwordText != '' ? 'color: #330000;border-bottom: 1px solid #330000;' : ''" :type="inputType" v-model="passwordText" :placeholder="translateTextData.Enter_your_password" @focus="passwordFocus">
                    <img v-show="passwordImg && passwordSwitch" @mousedown="passwordClick" src="@/assets/images/login/closeEyes.png">
                    <img v-show="passwordImg && !passwordSwitch" @mousedown="passwordClick" src="@/assets/images/login/openEyes.png">
                </div>
                <!-- <div>
                    <span>Email verification</span>
                    <input type="text" v-model="emailVerify" placeholder="E-mail verification code" style="width: calc(100% - 150px);padding-right: 150px;">
                    <p v-if="codeShow" class="code" @click="emailCodeClick()">{{codeText}}</p>
                    <p v-if="!codeShow" class="code">{{codeTime}}s</p>
                </div> -->
                <div>
                    <span>{{translateTextData.Confirm_Password}}</span>
                    <input :style="passwordText1 != '' ? 'color: #330000;border-bottom: 1px solid #330000;' : ''" :type="inputType1" v-model="passwordText1" :placeholder="translateTextData.Please_confirm_your_password" @focus="passwordFocus1">
                    <img v-show="passwordImg1 && passwordSwitch1" @mousedown="passwordClick1" src="@/assets/images/login/closeEyes.png">
                    <img v-show="passwordImg1 && !passwordSwitch1" @mousedown="passwordClick1" src="@/assets/images/login/openEyes.png">
                </div>
                <div class="agreement">
                    <div class="radio" @click="radio = !radio">
                        <img v-show="!radio" src="@/assets/images/login/radio.png">
                        <img v-show="radio" src="@/assets/images/login/radio1.png">
                    </div>
                    <p>I agree to the <span @click="$router.push('/pivacy')">Pivacy & Cookie Policy</span> and  <span @click="$router.push('/terms')">Terms & Conditions</span></p>
                </div>
            </div>
            <button @click="signClick()">{{this.translateTextData.SIGN_IN}}</button>
            <span v-show="!login">{{translateTextData.New_user_coupons}}</span>
        </div>

        <el-dialog title="Please enter the image verification code" :visible.sync="dialogVisible" width="30%" center>
            <div class="code">
                <input type="text" v-model="codeVerify">
                <div class="img" @click="getCaptcha()">
                    <el-image :lazy-src="captcha.img" :src="captcha.img" style="cursor: pointer;width: 100%;height: 100%;" alt="Loading failed">
                        <div slot="error">
                            {{translateTextData.Loading_failed}}
                        </div>
                        <div slot="placeholder">
                            {{translateTextData.Loading_failed}}
                        </div>
                    </el-image>
                    <!-- <img :src="captcha.img" alt="Loading failed"> -->
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <button @click="dialogClick()" style="outline: none;border: none;cursor: pointer;width: 188px;height: 40px;background: #330000;display: flex;justify-content: center;align-items: center;font-size: 16px;
                    font-family: Montserrat,SF-UI;font-weight: bold;color: #FFFFFF;margin: 0 auto;">Confirm</button>
            </span>
        </el-dialog>
    </div>
</template>

<style lang="scss" scoped>
@import "./login.scss";
</style>

<script>
import login from "./login.js"

export default {
    name: "login",
    components: {

    },
    mixins: [login]
}
</script>